<template>
  <div class="flex justify-end">
    <div class="flex items-end border-green-300 w-10/12 md:w-5/12 bg-msg m-3 rounded-tl-lg
    rounded-tr-lg rounded-bl-lg chat-user">
      <div class="p-1.5 w-full flex flex-col">
        <MediaItem :type="message.type" :location="message.location" @get-messages="refreshMessages"
                   :media="message.message_media" v-if="message.message_media"/>
        <div v-if="message.type === 'location'" class="w-full h-64">
          <!--    eslint-disable-next-line-->
          <GMapMap class="h-64"
                   :center="center"
                   :zoom="15"
                   map-type-id="terrain"
                   :options="{
                        zoomControl: false,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: true,
                        fullscreenControl: false,
                      }"
                   style="width: 100%; height: 100%"
          >
            <GMapMarker
              :position="marker.position"
            />
          </GMapMap>
        </div>
        <div v-if="message.body && message.type !== 'interactive'&& message.type !== 'location'"
             class="text-sm text-gray-600 p-2 text-left">
          <p class="whitespace-pre-wrap break-words">{{ message.body }}</p>
        </div>
        <div v-if="message.type === 'location'"
             class="text-sm break-words text-gray-600 text-left p-2">
          <p v-if="formattedLocation.name"><b>Nombre:</b> {{ formattedLocation.name }}</p>
          <p v-if="formattedLocation.address"><b>Dirección:</b> {{ formattedLocation.address }}</p>
          <a target="_blank" :href="locationUrl">
            <p>{{ locationUrl }}</p>
          </a>
        </div>
        <div v-if="message.type === 'interactive'"
             class="text-sm break-words text-gray-600 text-left p-2">
          <template v-if="this.activeConversation.form_provider === 'facebook-messenger'">
            <template v-if="message.interactive?.quick_replies">
              <h5 v-if="message.interactive.text">
                <b>{{ message.interactive.text }}</b>
              </h5>
              <div v-for="(row) in message.interactive?.quick_replies"
                   v-bind:key="row.payload">
                <h6 class="text-md font-semi-bold">{{ row.title }}</h6>
              </div>
            </template>
            <template v-if="message.interactive?.attachment?.payload?.template_type === 'button'">
              <p v-if="message.interactive?.attachment?.payload?.text">
                {{ message.interactive?.attachment?.payload?.text }}
              </p>
              <button v-for="(button) in message.interactive?.attachment?.payload?.buttons"
                      v-bind:key="button.payload" class="bg-gray-200 hover:bg-gray-400 text-black
                    font-bold py-2 px-4 border-1 border-b-4 border-gray-600 hover:border-gray-500
                    rounded mx-2">
                {{ button.title }}
              </button>
            </template>
          </template>
          <template v-else>
            <template v-if="message.interactive?.type === 'list'">
              <p v-if="message.interactive.header">
                {{ message.interactive.header.text }}
              </p>
              <p v-if="message.interactive.body">
                {{ message.interactive.body.text }}
              </p>
              <div v-for="(section, key) in message.interactive.action.sections"
                   v-bind:key="key">
                <h5 class="text-base font-bold">{{ section.title }}</h5>
                <div v-for="(row) in section.rows"
                     v-bind:key="row.id">
                  <h6 class="text-md font-semi-bold">{{ row.title }}</h6>
                  <p class="text-sm" v-if="row.description !== '' && row.description !== null">
                    {{ row.description }}
                  </p>
                </div>
              </div>
              <p v-if="message.interactive.footer">
                {{ message.interactive.footer.text }}
              </p>
              <button class="bg-gray-200 hover:bg-gray-400 text-black font-bold py-2 px-4 border-1
            border-b-4 border-gray-600 hover:border-gray-500 rounded mx-2">
                {{ message.interactive.action.button }}
              </button>
            </template>
            <template v-if="message.interactive?.type === 'button'">
              <p v-if="message.interactive.header">{{ message.interactive.header.text }}</p>
              <p v-if="message.interactive.body">{{ message.interactive.body.text }}</p>
              <button v-for="(button) in message.interactive.action.buttons"
                      v-bind:key="button.reply.id" class="bg-gray-200 hover:bg-gray-400 text-black
                    font-bold py-2 px-4 border-1 border-b-4 border-gray-600 hover:border-gray-500
                    rounded mx-2">
                {{ button.reply.title }}
              </button>
              <p v-if="message.interactive.footer">{{ message.interactive.footer.text }}</p>
            </template>
          </template>
        </div>
        <div v-if="message.type === 'template'">
          <template v-if="message.interactive?.header">
            <div v-for="(hd, y) in message.interactive?.header" v-bind:key="y" class="py-1">
              <template v-if="hd.type === 'image'">
                <img :src="hd?.value" alt="img" style="width: 100%"/>
              </template>
              <template v-if="hd.type === 'video'">
<!--                eslint-disable-next-line-->
                <video style="width: 100%" height="auto" controls >
                  <source :src="hd.value">
                  Your browser does not support the video tag.
                </video>
              </template>
            </div>
          </template>
          <template v-if="message.interactive?.body">
            <p class="whitespace-pre-wrap py-1"
               v-for="(text, key) in message.interactive?.body" v-bind:key="key">
              {{ text?.value }}
            </p>
          </template>
          <template v-if="message.interactive?.texts">
            <p class="whitespace-pre-wrap py-1"
               v-for="(text, key) in message.interactive?.texts" v-bind:key="key">
              {{ text?.value ? text?.value : text }}
            </p>
          </template>
          <!--          <template v-else>-->
          <!--  <p v-for="(component, key) in message.interactive.components" v-bind:key="key">-->
          <!--              {{component.text}}-->
          <!--            </p>-->
          <!--          </template>-->
        </div>
        <div class="flex justify-end">
          <div class="text-xs text-gray-400 text-right">{{ message.human_created }}</div>
          <img class="w-5 mx-2" v-if="message.send && !message.delivered && !message.seen"
               src="@/assets/one_check.svg" alt="enviado">
          <img class="w-5 mx-2" v-if="message.delivered && !message.seen"
               src="@/assets/doble_check_gris.svg" alt="recibido">
          <img class="w-5 mx-2" v-if="message.seen" src="@/assets/doble_check_azul.svg" alt="leído">
        </div>

        <div class="text-xs text-red-600 text-right" v-if="!message.send">No enviado</div>
        <div class="text-xs text-red-600 text-right" v-if="message.errors">
          Errores:
          <p class="whitespace-pre-wrap break-words">
            {{ message.errors }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import MediaItem from '@/components/chat/MediaItem.vue';

export default {
  name: 'OutcomeMessage',
  components: { MediaItem },
  props: {
    message: Object,
  },
  methods: {
    refreshMessages() {
      this.$emit('get-messages');
    },
  },
  computed: {
    activeConversation() {
      return this.$store.state.activeConversation;
    },
    locationUrl() {
      if (this.message.type === 'location') {
        switch (this.activeConversation.form_provider) {
          case 'facebook-messenger':
            return `https://www.google.com.ec/maps/@${this.formattedLocation.lat},${this.formattedLocation.long},18z?hl=es`;
          case 'whatsapp':
          case 'dialog360':
          default:
            return `https://www.google.com.ec/maps/@${this.formattedLocation.latitude},${this.formattedLocation.longitude},18z?hl=es`;
        }
      }
      return '';
    },
    center() {
      if (this.message.type === 'location') {
        switch (this.activeConversation.form_provider) {
          case 'facebook-messenger':
            return { lat: this.formattedLocation.lat, lng: this.formattedLocation.long };
          case 'whatsapp':
          case 'dialog360':
          default:
            return { lat: this.formattedLocation.latitude, lng: this.formattedLocation.longitude };
        }
      }
      return { lat: -0.1806532, lng: -78.4678382 };
    },
    marker() {
      if (this.message.type === 'location') {
        switch (this.activeConversation.form_provider) {
          case 'facebook-messenger':
            return {
              position: {
                lat: this.formattedLocation.lat, lng: this.formattedLocation.long,
              },
            };
          case 'whatsapp':
          case 'dialog360':
          default:
            return {
              position: {
                lat: this.formattedLocation.latitude, lng: this.formattedLocation.longitude,
              },
            };
        }
      }
      return { lat: -0.1806532, lng: -78.4678382 };
    },
    formattedLocation() {
      if (this.message.type === 'location') {
        return JSON.parse(this.message.location);
      }
      return null;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.bg-msg {
  background-color: #d3f9d0;
}

.chat-user {
  border: solid 1px #d4d5d8;
  box-shadow: -3px 4px 11px 0px #a6c4c6;
}
</style>
